import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationState, UserForCreation, UserGroupRepositoryService, UserRepositoryService, eUserType } from 'reg-hub-common';
import { ConfigurationDetails, ConfigurationDetailsRepository } from 'reg-hub-common';
import { HttpParams } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.css']
})
export class AddUserComponent {
    private routeParamSubscription!: Subscription;
    isSaving: boolean = false;
    hasAccess: boolean = false;
    corporationID: string = "";
    userGroupID: string = "";
    configurationDetailsOptions: ConfigurationDetails[] = [];

    apiUserType = eUserType.Api;
    portalUserType = eUserType.Portal;
    passwordFieldLabel = "Password";
    confirmPasswordFieldLabel = "Confirm Password";
    hidePassword: boolean = true;
    confirmPassword: string = "";

    newUser: UserForCreation = {
        userGroupID: "",
        userName: "",
        password: "",
        firstName: "",
        middleName: "",
        lastName: "",
        isTemporaryPassword: true,
        userTypeID: eUserType.Api,
        configurationDetailsID: null,
        isTwoFactorEnabled: false,
        contactDetails: {
            email: "",
            phoneNumber: "",
            faxNumber: "",
            address: {
                streetNumber: "",
                streetName: "",
                unit: "",
                city: "",
                jurisdiction: "",
                postalCode: "",
                countryCode: "CA"
            }
        }
    };

    constructor(private userGroupRepo: UserGroupRepositoryService,
        private usersRepo: UserRepositoryService,
        private configDetailsRepo: ConfigurationDetailsRepository,
        private configurationState: ConfigurationState,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private messageService: ToastrService) { }

    ngOnInit(): void {
        this.configurationState.getCorporationID().then(corpID => {
            this.corporationID = corpID;
            this.configurationState.hasUserManagementAccess().then(userAccess => {
                this.hasAccess = userAccess;
                if (!this.hasAccess) {
                    //Shouldn't be here
                    this.messageService.error("Access Denied!");
                }
                else {
                    this.routeParamSubscription = this.activatedRoute.paramMap.subscribe(userGroupParams => {
                        // check current slug for user group id
                        this.userGroupID = userGroupParams.get('userGroupID')!;

                        this.getUserGroup(this.userGroupID);
                    });

                    this.passwordFieldLabel = this.newUser.userTypeID === this.apiUserType ? "Password" : "Temporary Password";
                }
            })
        })
    }

    ngOnDestroy() {
        if (this.routeParamSubscription) {
            this.routeParamSubscription.unsubscribe();
        }
    }

    private getUserGroup = (userGroupID: string) => {
        this.userGroupRepo.getUserGroup(userGroupID)
            .subscribe(group => {
                this.getConfigurationDetails(group.corporationID);
            })
    }

    private getConfigurationDetails = (corporationID: string) => {
        this.configDetailsRepo.populateBaseLink([`${corporationID}`]);

        var configParams = new HttpParams();
        configParams.set('PageSize', 100);

        this.configDetailsRepo.getFirstPageSubscribable(configParams)
            .subscribe(configs => {
                this.configurationDetailsOptions = configs;
            });
    }

    saveUser() {
        if (!this.hasAccess) {
            //How did you get here?
            this.messageService.error("Access Denied!");
        }

        if (!this.confirmPasswordMatches()) {
            this.messageService.error("Passwords do not match");
            return;
        }

        this.isSaving = true;
        this.newUser.userGroupID = this.userGroupID;

        this.usersRepo.createUser(this.corporationID, this.userGroupID, this.newUser)
            .subscribe(result => {
                if (result.id != "") {
                    this.isSaving = false;
                    this.router.navigate(['management', 'userGroups', this.userGroupID]);
                }
            });
    }

onUserTypeSelectionChange(event: any) {
    this.passwordFieldLabel = event.value === this.apiUserType ? "Password" : "Temporary Password";
    this.confirmPasswordFieldLabel = "Confirm " + this.passwordFieldLabel;
}

togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
}

confirmPasswordMatches(): boolean {
    return this.newUser.password == this.confirmPassword;
}
}