import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ConfigurationState, eUserType, RegHubApiResult, User, UserRepositoryService } from 'reg-hub-common';
import { ConfigurationDetails, ConfigurationDetailsRepository } from 'reg-hub-common';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';
import { HttpParams } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { authConfig } from 'projects/reg-hub-client/src/environments/environment';

@Component({
    selector: 'app-user-info',
    templateUrl: './user-info.component.html',
    styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {
    protected corporationID: string = "";
    private routeParamSubscription!: Subscription;
    hasAccess: boolean = false;
    user: User | any = null
    configurationDetailsOptions: ConfigurationDetails[] = []
    apiUserType = 'Api';
    portalUserType = 'Portal';
    shouldShowTwoFactor: boolean = false;
    userDidLoadWithTwoFactorEnabled: boolean = false;

    constructor(private userRepo: UserRepositoryService,
        private configurationState: ConfigurationState,
        private configurationDetailsRepo: ConfigurationDetailsRepository,
        private router: Router,
        private messageService: ToastrService,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.configurationState.hasUserManagementAccess().then(userAccess => {
            this.hasAccess = userAccess;
            if (!this.hasAccess) {
                this.messageService.error("Access Denied!");
            }
            else {
                this.routeParamSubscription = this.activatedRoute.paramMap.subscribe(userGroupParams => {
                    // check current slug for user group id
                    const userGroupID = userGroupParams.get('userGroupID')!;
                    const userID = userGroupParams.get('userID')!

                    this.configurationState.getCorporationID().then(corporationID => {
                        this.corporationID = corporationID;
                        this.getConfigurationDetails();
                        this.getUser(userGroupID, userID);
                    })
                });
            }
        })
    }

    ngOnDestroy() {
        if (this.routeParamSubscription) {
            this.routeParamSubscription.unsubscribe();
        }
    }

    private getUser = (userGroupdID: string, userID: string) => {
        this.userRepo.getUser(this.corporationID, userGroupdID, userID)
            .subscribe(user => {
                this.user = user;
                if (this.user.userTypeID == eUserType.Portal
                    || String(this.user.userTypeID) == this.portalUserType) {
                    this.shouldShowTwoFactor = true;
                }
                this.userDidLoadWithTwoFactorEnabled = this.user.isTwoFactorEnabled;
            });
    }

    private getConfigurationDetails = () => {
        this.configurationDetailsRepo.populateBaseLink([`${this.corporationID}`]);

        var configParams = new HttpParams();
        configParams.set('PageSize', 100);

        this.configurationDetailsRepo.getFirstPageSubscribable(configParams)
            .subscribe(configs => {
                this.configurationDetailsOptions = configs;
            });
    }

    updateUserDetails() {
        if (!this.hasAccess) {
            //How did you get here?
            this.messageService.error("Access Denied!");
        }

        this.userRepo.updateUser(this.corporationID, this.user!.userGroupID, this.user!)
            .subscribe(result => {
                this.openSnackBar("Save successful");

                // If we need to send a two factor email, navigate through Identity Server
                if (!this.userDidLoadWithTwoFactorEnabled && this.user!.isTwoFactorEnabled) {
                    const returnUrl = encodeURIComponent(window.location.origin + `/management/userGroups/${this.user.userGroupID}`);
                    const qrCodeEmailUrl = `${authConfig.authority}/account/emailqrcode?username=${this.user!.userName}&returnurl=${returnUrl}`;
                    window.location.href = qrCodeEmailUrl;
                } else {
                    this.router.navigate(['management', 'userGroups', this.user!.userGroupID as string]);
                }
            })
    }

    openSnackBar(message: string) {
        const config = new MatSnackBarConfig();
        config.duration = 5000; // Set the duration in milliseconds (2 seconds)

        this.snackBar.open(message, 'Close', config);
    }

    onUserTypeSelectionChange(event: any) {
        if (event.value == this.portalUserType) {
            this.user!.isTemporaryPassword = true;
            this.shouldShowTwoFactor = true;
        }
        else {
            this.user!.isTemporaryPassword = false;
            this.shouldShowTwoFactor = false;
        }
    }
}