<div>
    <div>
        <div class="flex-container">
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Phone</mat-label>
                <input matInput name="middle-name" [(ngModel)]="this.contactDetails.phoneNumber" 
                    [disabled]="!enabled" placeholder="Phone">
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Fax</mat-label>
                <input matInput name="last-name" [(ngModel)]="this.contactDetails.faxNumber" 
                    [disabled]="!enabled" placeholder="Fax">
            </mat-form-field>
        </div>

        <div class="flex-container">
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Email</mat-label>
                <input matInput name="first-name" [(ngModel)]="this.contactDetails.email" 
                    [disabled]="!enabled" placeholder="Email">
            </mat-form-field>   
        </div>
    </div>

    <div>
        <h3>Address</h3>
        <div class="flex-container">
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Street Number</mat-label>
                <input matInput name="street-number" [(ngModel)]="this.contactDetails.address.streetNumber"
                    [disabled]="!enabled" placeholder="Street Number">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Street Name</mat-label>
                <input matInput name="street-name" [(ngModel)]="this.contactDetails.address.streetName" 
                    [disabled]="!enabled" placeholder="Street Name">
            </mat-form-field>
        </div>
        
        <div class="flex-container">
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Unit</mat-label>
                <input matInput name="street-unit" [(ngModel)]="this.contactDetails.address.unit" 
                    [disabled]="!enabled" placeholder="Unit">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>City</mat-label>
                <input matInput name="city" [(ngModel)]="this.contactDetails.address.city" 
                    [disabled]="!enabled" placeholder="City">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-field">
                <mat-label>Postal Code</mat-label>
                <input matInput name="postal-code" [(ngModel)]="this.contactDetails.address.postalCode" 
                    [disabled]="!enabled" placeholder="Postal Code">
            </mat-form-field>
        </div>

        <div class="flex-container-no-padding">
            
            <lib-jurisdiction-selection [appearance]="appearance" 
            [enabled]="enabled"
            [countryFieldName]="'CountryCode'" [jurisdictionFieldName]="'Jurisdiction'"
            [defaultCountry]="this.contactDetails.address.countryCode ?? 'CA'"
            [defaultJurisdiction]="this.contactDetails.address.jurisdiction ?? ''"
            (jurisdictionChosenEvent)="handleJurisdictionChangeEvent($event)"
            (countryChosenEvent)="handleCountryChangeEvent($event)"
            ></lib-jurisdiction-selection>
        </div>
    </div>
</div>